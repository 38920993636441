.container-projects {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 720px) {
  .container-projects {
    flex-direction: column;
  }
}