button {
  background-color: transparent;
  border-color: transparent;
}

.navbar-brand:focus,
.dropdown-toggle:focus,
.nav-link:focus {
  outline: transparent;
}

.error {
  color: red;
}

.input-container {
  display: flex;
  width: 100%;
}

.input-container i {
  border-radius: 1px;
  padding: 7px;
  min-width: 45px;
  text-align: center;
  color: white;
  background: #69487a;
}

.div-align-center {
  width: 100%;
  display: flex;
  text-align: center;
}

h1 {
  text-align: center;
}