input::-webkit-input-placeholder {
  font-size: smaller;
}

textarea::-webkit-input-placeholder {
  font-size: smaller;
}

input::-moz-placeholder {
  font-size: smaller;
}

textarea::-moz-placeholder {
  font-size: smaller;
}

.reduce-place {
  font-size: smaller;
}