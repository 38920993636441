.v-divider {
  margin-left: 1px;
  margin-right: 1px;
  width: 1px;
  border-right: 1px solid gray;
}

.box {
  width: 100%;
  text-align: center;
  margin: 1rem;
}

.count-box {
  color: #F47B36;
}

.nav .nav-item .nav-link.active {
  border-bottom: 4px solid green;
}